<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-users' }">{{
            $t("USERS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
          <template v-if="!firstLoader && form.isAdmin">
            <v-divider></v-divider>
            <b-form-row v-if="'password' in form">
              <b-col>
                <b-form-group
                  v-bind:id="'password-password-input'"
                  v-bind:label="$t('PASSWORD')"
                  v-bind:label-for="'password-password-input'"
                >
                  <FormInput
                    :id="'password-password-input'"
                    :type="'text'"
                    :name="'password'"
                    :required="'true'"
                    :feedback_id="'password-password-feedback'"
                    :i18n="{}"
                    :validations="{}"
                    :validateState="validateState('password')"
                    v-model="form.password"
                  >
                    <template v-slot:button-append>
                      <div class="input-group-append">
                        <span
                          @click="generateRandomPassword()"
                          class="btn btn-primary font-weight-bolder mr-2"
                        >
                          <v-icon dark>mdi-restart</v-icon>
                          {{ $t("GENERATE_PASSWORD") }}
                        </span>
                      </div>
                    </template>
                  </FormInput>
                </b-form-group>
              </b-col>
            </b-form-row>
          </template>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <v-overlay :value="!firstLoader && isLoadingUser">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingUser"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_USER } from "@/modules/user/store/user.module";

//Role Module
import { FETCH_ROLES } from "@/modules/role/store/role.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      user: {},
      roles: [],
      rolesParams: {
        page: 1,
        itemsPerPage: 50,
        query: ""
      }
    };
  },
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: { name: "list-users" } },
      { title: "Add User" }
    ]);
    vm.generateFormOutOfSchemaJson(this.schemaJson);
    vm.getRoles();
    vm.setFormPassword();
    vm.$set(vm.form, "lang", vm.getLanguages[0].lang);
  },
  watch: {},
  computed: {
    ...mapGetters(["isLoadingUser", "getLanguages"]),
    ...mapState({
      errors: (state) => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "switch",
            groupId: "user-active-group",
            id: "user-active-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            default: false,
            i18n: {
              label: "ACTIVE",
              placeholder: "ACTIVE",
              validation: "VALID_ACTIVE"
            },
            validations: {
              required: true
            },
            model: "active"
          },
          {
            type: "switch",
            groupId: "user-is_admin-group",
            id: "user-is_admin-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "is_admin-live-feedback",
            colSm: "auto",
            divider: false,
            default: false,
            i18n: {
              label: "IS_ADMIN",
              placeholder: "IS_ADMIN",
              validation: "VALID_IS_ADMIN"
            },
            validations: {
              required: true
            },
            model: "isAdmin"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "store-role-group",
            id: "store-role-input",
            feedback_id: "role-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "ROLE",
              placeholder: "ROLE",
              validation: "VALID_ROLE"
            },
            options: this.roles,
            validations: {
              required: true
            },
            hidden: !this.form.isAdmin,
            model: "roleId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "store-lang-group",
            id: "store-lang-input",
            feedback_id: "lang-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "LANG",
              placeholder: "LANG",
              validation: "INVALID"
            },
            options: this.getLanguages,
            reduceKey: "lang",
            validations: {
              required: true
            },
            model: "lang"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-email-input",
            groupId: "user-email-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: true,
            i18n: {
              label: "EMAIL",
              placeholder: "EMAIL",
              validation: "VALID_EMAIL"
            },
            validations: {},
            translatable: false,
            hidden: !this.form.isAdmin,
            model: "email"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-firstName-input",
            groupId: "user-firstName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "FIRST_NAME",
              placeholder: "FIRST_NAME",
              validation: "VALID_FIRST_NAME"
            },
            validations: {
              required: true,
              minLength: 2,
              params: { name: "First Name" }
            },
            translatable: false,
            model: "firstName"
          },
          {
            type: "input",
            inputType: "text",
            id: "user-lastName-input",
            groupId: "user-lastName-group",
            required: "required",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "LAST_NAME",
              placeholder: "LAST_NAME",
              validation: "VALID_LAST_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "lastName"
          },
          {
            type: "datepickerbase",
            inputType: "datepickerbase",
            id: "user-contractDate-input",
            groupId: "user-contractDate-group",
            feedback_id: "user-live-feedback",
            divider: false,
            i18n: {
              label: "CONTRACT_DATE",
              placeholder: "CONTRACT_DATE",
              validation: "VALID_CONTRACT_DATE"
            },
            validations: {
              required: false
            },
            translatable: false,
            model: "contractDate"
          }
        ]
      };
    }
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    var passwordValidations = {};
    if (vm.form["email"] != "" && vm.form["isAdmin"] == true) {
      passwordValidations = {
        required: true,
        minLength: 8,
        params: { name: "Password" }
      };
    }

    if ("password" in vm.form) {
      tmpValidationObject.form["password"] =
        vm.setItemValidations(passwordValidations);
    }

    let roleValidations = {
      required: false
    };
    let emailValidations = {
      required: false
    };

    if (vm.form.isAdmin === true) {
      roleValidations = {
        required: true
      };
      emailValidations = {
        required: true
      };
    }

    tmpValidationObject.form["roleId"] = vm.setItemValidations(roleValidations);
    tmpValidationObject.form["email"] = vm.setItemValidations(emailValidations);

    return tmpValidationObject;
  },
  methods: {
    getRoles(search, loading = function () {}) {
      let vm = this;
      let params = this.rolesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_ROLES, apiParams)
        .then((data) => {
          vm.roles = data.data.items;
          vm.totalRoles = data.data.totalItemsCount;
          vm.form.roleId = vm.roles[0].id;
          vm.$nextTick(function () {
            vm.firstLoader = false;
          });
        })
        .catch((response) => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      //remove admin inputs if the user is not admin
      let filteredForm = {};
      filteredForm = { ...vm.form };
      if (vm.form.isAdmin == false) {
        filteredForm.email = undefined;
        filteredForm.roleId = undefined;
        filteredForm.password = undefined;
      }
      //<--remove admin inputs if the user is not admin
      this.$store
        .dispatch(SAVE_USER, filteredForm)
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-user",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-user" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-users" });
          }
        })
        .catch(function (response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    setFormPassword() {
      let vm = this;
      vm.$set(vm.form, "password", "");
    },
    generateRandomPassword() {
      let vm = this;
      var randomString = Math.random().toString(36).slice(-8);
      vm.form.password = randomString;
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
